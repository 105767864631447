
// Imports
  @import 'modules/_fonts';
  @import 'modules/_all';
// END Imports

* {
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
  color: $main-link;

  &:hover {
    color: $main-link-hover;
  }
}

a,
button {
  cursor: pointer;
}

a,
button,
.dd-icon,
.dd-box-list li {
  @include fade;
}

a[href^="tel"]{
  color:inherit;
  text-decoration:none;
}

html, body {
  height: 100%;
}

body {
  font-family: $font-light;
  background: url(#{$body_image}) 0px -196px repeat #FFF;
  font-weight: normal;
  font-size: 13px;
}

img {
  max-width: 100%;
  border: none;
}

img,
input,
textarea,
select,
a,
button {
  border: none;
  background-color: $transparent;
}

img:focus,
input:focus,
textarea:focus,
select:focus,
a:focus,
button:focus {
  outline: none;
}

figure {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: 100;
}

.main {
  @extend .dd-normal;

  .box {
    @extend .dd-normal-box;
  }
}

$position: '#position';
$top: '#top';

.all-center {
  #{ $position }: absolute;
  #{ $top }: 50%;
  display: table-cell;
  vertical-align: middle;
}

.center {
  #{ $position }: relative;
  #{ $top }: -50%;
}

.dd-isrelative {
  position: relative;
}

.dd-regular {
  font-family: $font;
}

.dd-bold {
  font-family: $font-bold;
}

// HEADER
////////////////////////
header#main-header {
  background: orange;
  width: 100%;

  //Box container top
  .dd-container-top {
    width: 100%;
    background: $orange;
    min-height: 102px;
    padding: 10px 0;

    .dd-normal-box-header {
      position: relative;
    }

    .dd-logo {
      position: absolute;
      left: 0;
      top: 13px;
      width: 84px;
      min-height: 122px;
      z-index: 50;

      @media screen and (max-width: $tablet) {
        width: 70px;
        top: 6px;
      }
    }

    .dd-box-right {
      position: absolute;
      right: 0;

      @media screen and (max-width: $tablet) {
        width: 100%;
        position: relative;
        text-align: right;
      }
    }

    input {
      @include placeholder ('color', $white);
    }

    .dd-input-search {
      width: 142px;
      height: 16px;
      font-size: 9px;
      color: $white;
      text-align: right;
      margin-right: 6px;
      letter-spacing: 1px;
    }

    .dd-icon-search {
      background: url(/assets/images/icon-search.png) center center no-repeat;
      width: 16px;
      height: 16px;
      cursor: pointer;
    }

    .dd-line-number {
      margin-top: 12px;

      @media screen and (max-width: $tablet) {
        display: none;
      }
    }

    .dd-icon-tel {
      background: url(/assets/images/icon-tel-header.png) center center no-repeat;
      width: 33px;
      height: 33px;
      border: 1px solid $white;
      margin-right: 9px;
      margin-top: 5px;
    }

    .dd-tel-number {
      color: $white;
      text-align: left;

      span:first-of-type {
        font-size: 11px;
      }

      span:last-of-type {
        font-size: 25px;
      }
    }

    .dd-quote-header {
      color: #a75c0b;
      margin: 0 auto;
      display: block;
      text-align: center;
      font-size: 19px;
      font-weight: 100;
      margin-top: 48px;
      width: 100%;
      opacity: .9;
      position: absolute;
      right: 0;

      @media screen and (max-width: $tablet) {
        font-size: 17px;
        text-align: right;
        position: relative;
        margin-top: 19px;
      }

      @media screen and (max-width: $mobile) {
        width: 53%;
        font-size: 12px;
        margin-right: 0;
        margin-top: 11px
      }
    }

    // Menu Responsivo
    .dd-line-menu-responsive {
      margin-top: 5px;
      display: none;

      @media screen and (max-width: $tablet) {
        display: block;
      }

      .dd-tel-number {
        font-size: 20px;
        margin-top: 10px;
        margin-right: 9px;

        @media screen and (max-width: $mobile) {
          font-size: 14px;
          margin-top: 15px;
          margin-right: 5px;
        }
      }

      .responsive-nav {
        width: 33px;
        height: 33px;
        margin-top: 5px;
        border: 1px solid $white;
      }

      .slicknav_menu {
        background: transparent;
        position: absolute;
        z-index: 1;
        right: 0;
        padding: 0;

        .slicknav_icon-bar {
          box-shadow: none;
        }
      }

      .slicknav_btn {
        padding: 0;
        background: transparent;
        margin: 10px 8px 15px;
      }

      .slicknav_nav {
        background: $orange;
        border: 1px solid $white;
      }
    }// fim Menu Responsivo

  }// fim Box container topo


  //Box container bottom
  .dd-container-bottom {
    width: 100%;
    background: $brown;
    min-height: 45px;

    @media screen and (max-width: $tablet) {
      min-height: 17px;
    }

    // Main menu
    .main-menu {
      font-size: 13px;
      padding-top: 15px;
      letter-spacing: 1px;
      font-weight: normal;
      position: absolute;
      right: 0;

      @media screen and (max-width: $large) {
      	font-size: 11px;
        padding-top: 17px;
      }

      @media screen and (max-width: $tablet) {
        display: none;
      }

      li {
        margin-right: 67px;

        @media screen and (max-width: $large) {
        	margin-right: 22px;
        }

        &:last-of-type {
          margin: 0;
        }

        a{
          color: $white;

          &:hover {
            color: $orange;
          }
        }
      }
    }// fim Main menu

  }// fim Box container bottom

}// fim HEADER

// Elementos do Slider
////////////////////////
.dd-small-arrow-slider {
  background-image: url(/assets/images/arrows-slider-partners.png);
  background-repeat: no-repeat;
  width: 15px;
  height: 100%;
  position: absolute;
  top: 0;
  cursor: pointer;
  z-index: 1;
}

.dd-small-arrow-slider.dd-arrow-left {
  background-position: left center;
  left: 0;
}

.dd-small-arrow-slider.dd-arrow-right {
  background-position: right center;
  right: 0;
}
// fim Elementos do Slider

// Section know
.dd-section-know {
  padding-top: 36px;

  .dd-title {
    color: #764016;
    font-size: 30px;
    display: inline-block;
  }

  .dd-link-title {
    text-align: center;
    margin: 0 auto;
    display: block;
    max-width: 340px;
    margin-bottom: 38px;
  }

  li {
    vertical-align: top;
  }

  // Dois boxes
  .dd-know-2-col {
    width: 100%;
    max-width: 862px;
    margin: 0 auto;

    li {
      width: 46%;
      margin-right: 7%;

      @media screen and (max-width: $tablet) {
        width: 100%;
        text-align: center;
        margin-bottom: 30px;
        margin-right: 0;
      }
    }

    li:last-child {
      margin-right: 0;
    }
  }// fim dois boxes

  // Tres boxes
  .dd-know-3-col {
    li {
      width: 30%;
      margin-right: 5%;

      @media screen and (max-width: $tablet) {
        width: 100%;
        text-align: center;
        margin-bottom: 30px;
        margin-right: 0;
      }
    }

    li:last-child {
      margin-right: 0;
    }
  }// fim tres boxes

}// fim Section know

// Section partners
.dd-section-slider-footer {
  background: url(/assets/images/bg-parceiros.png) bottom center no-repeat;
  height: 197px;
  padding-top: 125px;
  padding-bottom: 55px;
  margin-top: -27px;

  .dd-title {
    color: $white;
    margin-top: 26px;
    margin-bottom: 30px;
    font-size: 31px;
  }

  .bx-wrapper {
    max-width: 762px !important;
    width: 100% !important;
    margin: 0 auto;
  }

  .dd-slider-partners {
    visibility: hidden;

    li {
      display: inline-block;
      vertical-align: middle;
    }

    li figure {
      height: 120px;
      width: 100%;
      text-align: center;
    }
  }

  .dd-arrow-left {
    @media screen and (max-width: 886px) {
      left: -15px;
    }
  }

  .dd-arrow-right {
    @media screen and (max-width: 886px) {
      right: -15px;
    }
  }
}// fim Section partners

// Section address
.dd-section-address {
  padding-top: 35px;
  padding-bottom: 43px;

  .dd-normal-box {
    @media screen and (max-width: $tablet) {
      text-align: center;
    }
  }

  .dd-pin-address {
    background: url(/assets/images/pin-icon.png) top center no-repeat;
    width: 24px;
    height: 37px;
    margin-right: 12px;

    @media screen and (max-width: $tablet) {
      vertical-align: top;
      margin-right: 5px;
    }
  }

  .dd-text-address {
    @include calc ('width', "100% - 177px");
    color: #560a01;
    font-size: 15px;
    padding-right: 15px;

    @media screen and (max-width: $tablet) {
      @include calc ('width', "100% - 165px");
      padding: 0;
    }

    @media screen and (max-width: $mobile) {
      width: 100%;
      margin-top: 15px;
    }
  }

  .dd-address-btn {
    border: 1px solid #560a01;
    color: #560a01;
    font-size: 16px;
    padding: 6px 16px;

    @media screen and (max-width: $tablet) {
      margin-top: 26px;
    }

    &:hover {
      color: white;
      background: #560a01;
    }
  }
}// fim Section address

// FOOTER
////////////////////////
footer#main-footer {

  //Box container topo
  .dd-container-top {
    width: 100%;
    background: $orange;
    display: inline-block;

    .dd-title-form {
      width: 216px;
      font-size: 20px;
      color: $brown;
      margin-right: 14px;

      @media screen and (max-width: $tablet) {
        width: 100%;
        margin-right: 0;
        text-align: center;
        padding-top: 24px;
        padding-bottom: 12px;
      }
    }

    input {
      background: $orange-dark;
      width: 100%;
      height: 43px;
      font-size: 13px;
      padding: 0 24px;
      margin-right: 12px;
      @include border-radius(11px);
      @include fade;
      @include placeholder ('color', "#854705");

      &:focus {
        background: #DA7100;
      }
    }

    .dd-container-btn-send {
      width: 15%;
      height: 32px;
      margin-right: 3px;
      vertical-align: middle;
      position: relative;

      @media screen and (max-width: $mobile) {
        width: 80px;
        margin: 0 auto;
        display: block;
        margin-top: 12px;
      }

      button {
        width: 100%;
        color: $white;
        font-size: 13px;
        border: 1px solid $white;
        padding: 6px 0;
        font-size: 15px;

        &:hover {
          color: $orange;
          background: $white;
        }
      }

      .dd-form-loader {
        margin-top: 4px;
      }

    }

    .dd-feedback-list-form {
      margin-top: 10px;
    }

    .dd-box-news-form {
      @include calc ('width', "100% - 230px");

      @media screen and (max-width: $tablet) {
        width: 100%;
      }
    }

    .dd-form-news {
      width: 100%;
      padding: 30px 0;

      @media screen and (max-width: $tablet) {
        padding-bottom: 40px;
      }

      .dd-msg-error {
        font-size: 12px;
        margin-bottom: 13px;
      }

      .dd-feedback-list-form {
        margin-bottom: 0;
      }
    }

    .dd-col-field {
      width: 28%;
      padding-right: 12px;
      vertical-align: middle;
      position: relative;

      @media screen and (max-width: $mobile) {
        width: 100%;
        margin-bottom: 27px;
        padding-right: 0;
      }

      span {
        font-size: 11px;
        top: -18px;
        left: 0;
        right: 0;
        padding-right: 12px;
        text-align: center;
        position: absolute;

        @media screen and (max-width: 1220px) {
          font-size: 10px;
        }

        @media screen and (max-width: $mobile) {
          font-size: 11px;
        }
      }
    }
  }// fim Box container topo

  //Box container bottom
  .dd-container-bottom {
    width: 100%;
    background: $brown url(/assets/images/bg-stripe.png) top left repeat;
    padding-top: 38px;
    padding-bottom: 30px;

    .dd-box-left {
      width: 40%;
      padding-left: 28px;

      @media screen and (max-width: 750px) {
        padding-left: 0;
      }

      @media screen and (max-width: 515px) {
        width: 100%;
        padding-top: 110px;
        display: block;
        margin: 0 auto;
      }
    }

    .dd-line-number {
      margin-bottom: 19px;

      @media screen and (max-width: 515px) {
        width: 100%;
        margin: 0 auto;
        margin-bottom: 12px;
        text-align: center;
      }
    }

    .dd-icon-tel {
      background: url(/assets/images/icon-tel-footer.png) center center no-repeat;
      width: 41px;
      height: 41px;
      border: 1px solid $orange;
      margin-right: 9px;
    }

    .dd-tel-number {
      color: $white;
      font-weight: 100;
      line-height: 17px;
      text-align: left;

      span:first-of-type {
        font-size: 19px;

        @media screen and (max-width: $tablet) {
          font-size: 18px;
        }
      }

      span:last-of-type {
        font-size: 30px;

        @media screen and (max-width: $tablet) {
          font-size: 24px;
        }
      }
    }

    .dd-site-map {
      font-size: 11px;
      list-style-type: none;
      color: $white;

      @media screen and (max-width: 515px) {
        text-align: center;
      }

      .dd-title {
        color: $orange;
        font-size: 12px;
      }

      li {
        margin-bottom: 5px;
      }

      a {
        color: $white;

        &:hover {
          color: $orange;
        }
      }
    }

    .dd-box-right {
      position: absolute;
      right: 0;
      top: 0;
      width: 60%;
      text-align: right;

      @media screen and (max-width: 515px) {
        width: 100%;
      }
    }

    .dd-line-social {
      width: 100%;
      margin-bottom: 32px;
    }

    .dd-social-title {
      color: $orange;
      font-size: 17px;
      margin-right: 15px;

      @media screen and (max-width: $large) {
        font-size: 15px;
        margin-right: 20px;
      }

      @media screen and (max-width: 850px) {
        margin-right: 0px;
        width: 100%;
        text-align: center;
        margin-bottom: 17px;
      }
    }

    .dd-social-list {
      @media screen and (max-width: 850px) {
        margin: 0 auto;
        display: block;
        width: 100%;
        text-align: center;
      }

      li {
        width: 43px;
        height: 43px;
        margin-right: 15px;

        @media screen and (max-width: $large) {
          margin-right: 10px;
        }

        &:last-of-type {
          margin-right: 0;
        }
      }

      a {
        display: block;
      }
    }

    .dd-box-facebook {
      @media screen and (max-width: $tablet) {
        display: none;
      }
    }
  }// fim Box container bottom

  .dd-container-right {
    background: $white;
    padding: 0 10px;

    .dd-normal-box-footer {
      max-width: 1035px;
      margin: 0 auto;
      display: block;
      padding: 20px 0;
    }

    p {
      width: 100%;
      text-align: center;
      font-size: 12px;
      color: #9d9d9d;
      vertical-align: middle;
      line-height: 21px;
      letter-spacing: .020em;
    }

    span {
      margin-left: 5px;
    }

    .dd-logo-insane {
      background: url(/assets/images/logo-insane.jpg) center center no-repeat;
      width: 41px;
      height: 16px;
      vertical-align: middle;
      margin-top: -3px;
    }

    .dd-logo-w3c {
      background: url(/assets/images/w3c.jpg) center center no-repeat;
      width: 39px;
      height: 20px;
      vertical-align: middle;
    }
  }

}// fim FOOTER

// SEARCH
.dd-wall {
  width: 100%;
}

.dd-ico-search {
  background: url(/assets/images/search-ico.png) center center no-repeat;
  width: 26px;
  height: 26px;
  cursor: pointer;
  background-position: -16px 0;
}

.dd-custom-ico-search {
  position: absolute;
  right: 10px;
  top: 10px;
}

input.dd-form-control {
  background-color: $white;
  border: 1px solid $lightbrown;
  padding: 12px 10px;
  font-size: 14px;
  width: 100%;
  @include placeholder('color', '#{$brown}');
  @include placeholder('font-weight', 'bolder');
}

.dd-padding-vertical-double {
  padding-top: 20px;
  padding-bottom: 20px;
}

.dd-form-loader-custom {
  display: block;
}

//Arrows banner
.dd-arrows-banner {
  width: 1160px !important;
  height: 0;
  top: 50%;
  margin-top: -32px !important;
  position: absolute;
  left: 0px;
  right: 0px;

  @media screen and (max-width: 1170px) {
    width: 95% !important;
  }

  .dd-arrow {
    background-image: url(/assets/images/arrows-slider-banner.png);
    background-repeat: no-repeat;
    width: 21px;
    height: 32px;
    cursor: pointer;
  }

  .dd-arrow-left {
    background-position: left top;
  }

  .dd-arrow-right {
    background-position: right top;
  }
}

//btn see all
.dd-see-all {
  width: 21%;
  height: 13px;
  padding: 14px 0;
  text-align: center;
  font-size: 12px;
  background-color: #f1f1f1;
  color: $brown;
  border: 1px solid $lightbrown;
  float: right;
  margin: 20px 0;

  @media screen and (max-width: 695px) {
    width: 100%;
    float: none;
    margin: 0;
    margin-bottom: 20px;
  }

  &:hover {
    background-color: $brown;
    color: $white;
  }
}

.dd-video-full-shows {
  width: 100%;
  max-height: 416px;
  height: 52vw;
  max-width: 661px;
}

.lb-outerContainer {
  background-color: transparent !important;
}

.dd-disabled {
  opacity: .5;
  pointer-events: none;
}

// Galeria
#lightbox {
  
  .lb-nav{
    width: 0;
    height: 0;
    position: initial;

    .lb-prev, .lb-next{
      position: absolute;
      z-index: 10;
      top: 0;
      height: 100%;
      width: 90px;
    }
  }

  .dd-share a{
    display: inline-block;
    width: 128px;
    height: 31px;
    margin-right: 10px;
  }

  .icon-facebook{
    display: block;
    width: 128px;
    height: 31px;
    background: url(/assets/images/facebook.png) no-repeat center center;
  }

}